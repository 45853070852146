import React, { useState, useContext, useEffect } from 'react';
import PropType from 'prop-types';
import { useTranslation } from 'react-i18next';

// Components
import Button from '../shared/Button';
import InputText from '../shared/InputText';

// Consts
import api, { logError } from '../../consts/api';
import InputDate from '../shared/InputDate';

import UserContext from '../../state-management/context/UserContext';
// import { SET_LOADING, SET_LOADING_MESSAGE } from '../../state-management/actions/actionTypes';

import ModalShareCode from '../shared/ModalShareCode';

const uploadShareCodeData = async (shareCode, dateOfBirth, onfidoApplicantId, image, visaType, firstName, lastName, expiryDate, detailsMatch, systemDown, side) => {
    await api.post('/new-hires/share-code', { shareCode, dateOfBirth, onfidoApplicantId, image, visaType, firstName, lastName, expiryDate, detailsMatch, systemDown, side })
}

const uploadMultipleShareCodeData = async (shareCode, dateOfBirth, onfidoApplicantId, image, visaType, firstName, lastName, expiryDate, detailsMatch, systemDown) => {
    let imgArr = [];
    if (!Array.isArray(image)) {
        imgArr.push(image);
    } else {
        imgArr = image;
    }
    const totalImages = imgArr.length;
    for (let i = 0; i < totalImages; i++) {
        const side = i + 1;
        await uploadShareCodeData(shareCode, dateOfBirth, onfidoApplicantId, imgArr[i], visaType, firstName, lastName, expiryDate, detailsMatch, systemDown, side);
    }
}

const CodeInput = ({ /* document, */
    updateDocumentIndex,
    onfidoApplicantId }) => {

    // eslint-disable-next-line
    const [userState, userDispatch] = useContext(UserContext);


    const [code, setCode] = useState('');
    // const [error, setError] = useState('');
    const [buttonClicked, setButtonClicked] = useState(false);
    const { t } = useTranslation();

    const [isOpen, setIsOpen] = useState(false);
    const [dateOfBirth, setDateOfBirth] = useState('');
    const [loadingModal, setLoadingModal] = useState(false);
    const [shareCodeDataDoNotMatch, setShareCodeDataDoNotMatch] = useState(false);
    const [shareCodeModalLabel, setShareCodeModalLabel] = useState('');

    const [counterShareCodeCalls, setCounterShareCodeCalls] = useState(0);

    useEffect(() => {
        if (!shareCodeDataDoNotMatch) {
            setButtonClicked(false);
        }
        // delete later
        setButtonClicked(false);
    }, [shareCodeDataDoNotMatch]);


    /**
     * Check if the code input is valid
     *
     * @returns {boolean}
     */
    const isCodeValid = () => {
        // Remove spaces from code for validation
        const cleanedCode = code.replace(/\s/g, '');

        // Check if the code is exactly 9 characters long and dateOfBirth is not empty
        return cleanedCode.length === 9 && dateOfBirth !== '' && !buttonClicked;
    };

    /**
     * Send the submitted share code to the backend
     */
    const onSubmit = () => {
        // setError('')
        setShareCodeDataDoNotMatch(false)
        // Prevent several share codes from being sent
        setButtonClicked(true);

        setLoadingModal(true);
        const shareCode = code.replace(/\s/g, '');
        api.post('/new-hires/share-code-bot', { shareCode: shareCode, dateOfBirth: dateOfBirth })
            .then((res) => {
                const { data } = res;
                const image = data.image;

                const { visaType, firstName, lastName, expiryDate, detailsMatch } = data.text;
                if (data.text.detailsMatch !== true) {
                    setShareCodeModalLabel(t('document-capture.share-code.error'));
                    setShareCodeDataDoNotMatch(true);
                    setCounterShareCodeCalls(counterShareCodeCalls + 1);

                    if (counterShareCodeCalls === 1) {
                        setShareCodeDataDoNotMatch(false);

                        // call sf with data
                        uploadMultipleShareCodeData(shareCode, dateOfBirth, onfidoApplicantId, image, visaType, firstName, lastName, expiryDate, detailsMatch, false)
                            .then(() => {
                                setLoadingModal(false);
                                updateDocumentIndex();
                            })
                            .catch(async (error) => {
                                setShareCodeModalLabel('There was an error sending the share code. Please try again.')
                                setShareCodeDataDoNotMatch(true);
                                setButtonClicked(false);
                                // setError(t('errors.unexpected-error'))

                                logError('Error caught from /new-hire/share-code endpoint (1)', error, userState.uuid);
                            });
                    }
                } else if (data.text.detailsMatch === true) {
                    // call sf with data
                    uploadMultipleShareCodeData(shareCode, dateOfBirth, onfidoApplicantId, image, visaType, firstName, lastName, expiryDate, detailsMatch, false)
                        .then(() => {
                            setLoadingModal(false);
                            updateDocumentIndex();
                        })
                        .catch(async (error) => {
                            setShareCodeModalLabel('There was an error sending the share code. Please try again.')
                            setShareCodeDataDoNotMatch(true);
                            setButtonClicked(false);
                            // setError(t('errors.unexpected-error'))

                            logError('Error caught from /new-hire/share-code endpoint (2)', error, userState.uuid);
                        });
                }
            })
            .catch((error) => {
                setShareCodeModalLabel('The details you have entered could not be retrieved from the Home Office website. This may be because of a Home Office technical issue. We please ask that you try again later.')
                setShareCodeDataDoNotMatch(true);
                setButtonClicked(false);
                // setError(t('errors.unexpected-error'))

                logError('Error caught from /new-hire/share-code-bot endpoint', error, userState.uuid);
                uploadMultipleShareCodeData(shareCode, dateOfBirth, onfidoApplicantId, null, null, null, null, null, null, true)
                    .then(() => {
                        // updateDocumentIndex();
                    })
                    .catch(async (error) => {
                        setShareCodeModalLabel('There was an error sending the share code. Please try again.')
                        setShareCodeDataDoNotMatch(true);
                        setButtonClicked(false);
                        // setError(t('errors.unexpected-error'))

                        logError('Error caught from /new-hire/share-code endpoint (3)', error, userState.uuid);
                    });
            });
    };

    const handleCodeChange = (event) => {
        // Convert to uppercase and remove special characters (!@#$%^) along with non-initial spaces
        let inputValue = event.target.value.toUpperCase().replace(/[^A-Z0-9]/g, '');

        // Initialize formattedValue
        let formattedValue = '';

        // Insert spaces to follow the 'XXX XXX XXX' pattern
        for (let i = 0; i < inputValue.length; i++) {
            // Insert first space after 3 characters and second space after additional 2 characters
            if (i === 3 || i === 6) {
                formattedValue += ' ';
            }
            formattedValue += inputValue[i];
        }

        setCode(formattedValue);
    };

    return (
        <div className="gov__code-input-container">
            {/* <p className="gov__step-title">{document.name.toUpperCase()}</p> */}
            <p className="gov__step-title">{t('document-capture.share-code.title').toUpperCase()}</p>

            {/* <p className="gov__document-combination-content">To generate your share code please access;</p>
            <a href="https://www.gov.uk/prove-right-to-work" target="_blank" rel="noreferrer">
              https://www.gov.uk/prove-right-to-work.
            </a>
            <p className="gov__document-combination-content">
                Enter the document number that you used to apply for your status as well as your date of birth. Then follow these steps:
            </p> */}
            <p className='gov__document-combination-content'>{t('document-capture.share-code.description')}</p>
            <a href="https://www.gov.uk/prove-right-to-work" target="_blank" rel="noreferrer">
                https://www.gov.uk/prove-right-to-work.
            </a>
            <p className='gov__document-combination-content'>{t('document-capture.share-code.disclaimer')}</p>

            <ul>
                <li><p className="gov__document-combination-content">{t('document-capture.share-code.bullet-points.1')}</p></li>
                <li><p className="gov__document-combination-content">{t('document-capture.share-code.bullet-points.2')}</p></li>
                <li><p className="gov__document-combination-content">{t('document-capture.share-code.bullet-points.3')}</p></li>
                <li><p className="gov__document-combination-content">{t('document-capture.share-code.bullet-points.4')}</p></li>
                <li><p className="gov__document-combination-content">{t('document-capture.share-code.bullet-points.5')}</p></li>
            </ul>

            <p className="gov__document-combination-content">{t('document-capture.share-code.enter-code')}</p>

            <div className='gov__code-input-container-div'>
                <p><i>({t('document-capture.share-code.note')})</i></p>
                <InputText
                    // RegEx for introducing a space after every 3rd character
                    onChangeHandler={handleCodeChange}
                    // 9 characters for ShareCode plus 2 spaces introduced by regex
                    maxLength={11}
                    // placeholder={"W-- --- ---"}
                    value={code}
                    label={t('document-capture.share-code.name')}
                />
                <InputDate
                    isOpen={isOpen}
                    setIsModalOpen={setIsOpen}
                    selected={dateOfBirth}
                    onChangeHandler={setDateOfBirth}
                    label={t('document-capture.share-code.enterBirthDate')}
                />
            </div>

            {/* { error && <p className="gov__form-error">{error}</p> } */}
            {loadingModal && <ModalShareCode setLoadingModal={setLoadingModal} shareCodeDataDoNotMatch={shareCodeDataDoNotMatch} setShareCodeDataDoNotMatch={setShareCodeDataDoNotMatch} label={shareCodeModalLabel} />}

            <Button
                isDisabled={!isCodeValid()}
                onClickHandler={() => onSubmit()}
                text={t('shared.continue').toUpperCase()}
            />
        </div>
    );
};

CodeInput.propTypes = {
    document: PropType.object,
    updateDocumentIndex: PropType.func,
    onfidoApplicantId: PropType.string
};

CodeInput.defaultProp = {
    document: {
        description: '',
        name: '',
    },
    updateDocumentIndex: null,

};

export default CodeInput;
